export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  AUD = 'AUD',
  CAD = 'CAD',
  GBP = 'GBP'
}

export const DEFAULT_CURRENCY = Currency.USD;

export function getCurrencySymbol(currency?: Currency) {
  switch (currency) {
    case Currency.EUR: return '€';
    case Currency.AUD: return 'A$';
    case Currency.CAD: return 'C$';
    case Currency.GBP: return '£';
    default: return '$';
  }
}


const noop = (n: number|undefined) => n ?? 0;
export const usdToUsd = noop;

// usd to/from eur
export const usdToEur = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * 0.951;
};
export const eurToUsd = (eur: number|undefined) => {
  if (eur === undefined) {
    return 0;
  }
  return eur / 0.951;
};

// usd to/from aud
export const usdToAud = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * 1.442
};
export const audToUsd = (aud: number|undefined) => {
  if (aud === undefined) {
    return 0;
  }
  return aud / 1.442;
};

// eur to/from aud
export const eurToAud = (eur: number|undefined) => {
  if (eur === undefined) {
    return 0;
  }
  return eur * 1.55;
};
export const audToEur = (aud: number|undefined) => {
  if (aud === undefined) {
    return 0;
  }
  return aud / 1.55;
};

// usd to/from cad
export const usdToCad = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd * 1.301;
};
export const cadToUsd = (cad: number|undefined) => {
  if (cad === undefined) {
    return 0;
  }
  return cad / 1.301;
};

// cad to/from eur
export const cadToEur = (cad: number|undefined) => {
  if (cad === undefined) {
    return 0;
  }
  return cad * 0.7;
};
export const eurToCad = (eur: number|undefined) => {
  if (eur === undefined) {
    return 0;
  }
  return eur / 0.7;
};

// cad to/from aud
export const cadToAud = (cad: number|undefined) => {
  if (cad === undefined) {
    return 0;
  }
  return cad * 1.11;
};
export const audToCad = (aud: number|undefined) => {
  if (aud === undefined) {
    return 0;
  }
  return aud / 1.11;
};

// gbp to/from usd
export const gbpToUsd = (gbp: number|undefined) => {
  if (gbp === undefined) {
    return 0;
  }
  return gbp * 1.233;
};
export const usdToGbp = (usd: number|undefined) => {
  if (usd === undefined) {
    return 0;
  }
  return usd / 1.233;
};

// gbp to/from cad
export const gbpToCad = (gbp: number|undefined) => {
  if (gbp === undefined) {
    return 0;
  }
  return gbp * 1.605;
};
export const cadToGbp = (cad: number|undefined) => {
  if (cad === undefined) {
    return 0;
  }
  return cad / 1.605;
};

// gbp to/from aud
export const gbpToAud = (gbp: number|undefined) => {
  if (gbp === undefined) {
    return 0;
  }
  return gbp * 1.778;
};
export const audToGbp = (aud: number|undefined) => {
  if (aud === undefined) {
    return 0;
  }
  return aud / 1.778;
};

// gbp to/from eur
export const gbpToEur = (gbp: number|undefined) => {
  if (gbp === undefined) {
    return 0;
  }
  return gbp * 1.172;
};
export const eurToGbp = (eur: number|undefined) => {
  if (eur === undefined) {
    return 0;
  }
  return eur / 1.172;
};

export type CurrencyTransformer = [(n?: number) => number, (n?: number) => number];
export function createCurrencyTransformer(currency?: Currency): CurrencyTransformer {
  if (currency === Currency.AUD) {
    return [usdToAud, audToUsd];
  }
  if (currency === Currency.EUR) {
    return [usdToEur, eurToUsd];
  }
  if (currency === Currency.CAD) {
    return [usdToCad, cadToUsd];
  }
  if (currency === Currency.GBP) {
    return [usdToGbp, gbpToUsd];
  }
  return [usdToUsd, usdToUsd];
}
