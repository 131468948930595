import { POST } from '~/common/client';
import { TOS_VERSION_ID } from '~/common/config';

import { getFirebaseToken } from '../auth';

const urlBase = process.env.NEXT_PUBLIC_SERVICE_URL_BASE;

async function getDefaultHeaders() {
  const firebaseToken = await getFirebaseToken();
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${firebaseToken}`
  };
}

export interface ErrorResponseData {
  message: string;
}

async function parseResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const message = (await response.json() as ErrorResponseData).message
    throw new Error(message);
  }
  const result = await response.json();
  return result.data;
}

export interface EntityResult {
  id: string;
}

export interface GetUserResult {
  id: string;
  email: string;
  emailDomain: string;
  firebaseUid: string;
}

export interface CheckTosAcceptanceResult {
  accepted: boolean;
}

export interface EntitlementResult {
  hasEntitlement: boolean;
}

export interface CreateUserParams {
  firebaseUid: string;
  email: string;
  hasReadTos: boolean;
}
export interface CreateUserResult {
  id: string;
  emailDomain: string;
}
export async function createUser(params: CreateUserParams): Promise<CreateUserResult> {
  const response = await fetch(`${urlBase}/users`, {
    method: POST,
    headers: await getDefaultHeaders(),
    body: JSON.stringify({
      ...params,
      tosVersionId: TOS_VERSION_ID
    })
  });

  return parseResponse<CreateUserResult>(response);
}

export async function getUserByFirebaseUid(firebaseUid: string): Promise<GetUserResult> {
  const response = await fetch(`${urlBase}/users/${firebaseUid}?field=firebaseUid`, {
    headers: await getDefaultHeaders()
  });
  return parseResponse<GetUserResult>(response);
}

export async function checkDlcohEntitlement(): Promise<EntitlementResult> {
  return checkEntitlement('dlcoh');
}

export async function checkEntitlement(featureName: string) {
  const response = await fetch(`${urlBase}/features/${featureName}?checkCurrentUser=1`, {
    headers: await getDefaultHeaders()
  });
  return parseResponse<EntitlementResult>(response);
}

export async function checkTosAcceptance(): Promise<CheckTosAcceptanceResult> {
  const response = await fetch(`${urlBase}/tos/${TOS_VERSION_ID}/acceptance`, {
    headers: await getDefaultHeaders()
  });
  return parseResponse<CheckTosAcceptanceResult>(response);
}

export async function acceptTos(): Promise<void> {
  const response = await fetch(`${urlBase}/tos/${TOS_VERSION_ID}/acceptance`, {
    headers: await getDefaultHeaders(),
    method: POST
  });
  return parseResponse<void>(response);
}

export interface GetApiTokenOfCurrentUserResult {
  value: string
}
export async function getApiTokenOfCurrentUser(): Promise<GetApiTokenOfCurrentUserResult|undefined> {
  const response = await fetch(`${urlBase}/current-user/api-token`, {
    headers: await getDefaultHeaders(),
  });
  return parseResponse<GetApiTokenOfCurrentUserResult>(response);
}

export interface CreateNewApiTokenResult {
  value: string
}
export async function createNewApiToken(): Promise<CreateNewApiTokenResult|undefined> {
  const response = await fetch(`${urlBase}/api-tokens`, {
    headers: await getDefaultHeaders(),
    method: POST
  });
  return parseResponse<GetApiTokenOfCurrentUserResult>(response);
}

export async function getWhitepaper202203() {
  const response = await fetch(`${urlBase}/documents/whitepapers/202203`, {
    headers: await getDefaultHeaders(),
  });
  return response.blob();
}

export async function getWhitepaper202305() {
  const response = await fetch(`${urlBase}/documents/whitepapers/202305`, {
    headers: await getDefaultHeaders(),
  });
  return response.blob();
}

export async function getWhitepaper202310() {
  const response = await fetch(`${urlBase}/documents/whitepapers/202310`, {
    headers: await getDefaultHeaders(),
  });
  return response.blob();
}

export async function getWhitepaper202402() {
  const response = await fetch(`${urlBase}/documents/whitepapers/202402`, {
    headers: await getDefaultHeaders(),
  });
  return response.blob();
}

export async function checkEmailAddress(emailAddress: string) {
  const response = await fetch(`${urlBase}/users/check-email-address/${emailAddress}`);
  const { isValid } = await parseResponse<{ isValid: boolean }>(response);
  return isValid;
}
