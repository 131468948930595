import React, { ReactElement } from 'react';
import { Drawer, List, Divider, IconButton, ListItem, ListItemText, ListSubheader, Link, Menu, Tooltip } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon, ChevronRight as ChevronRightIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { useMenuController } from '../menu';
import { useSidebarStyles } from './sidebar.styles';

export type ClickHandler = () => void;

export interface SidebarProps {
  logo: ReactElement;
  activeItem?: SidebarActiveItem;
  clickHandlers: {
    dlcoh: ClickHandler;
    slcoh: ClickHandler;
    pce: ClickHandler;
    offtaker: ClickHandler;
    gepb: ClickHandler;
    electrolysisManufacturingDashboard: ClickHandler;
    userAnalytics: ClickHandler;
  };
  paths?: {
    whitepapers?: string;
  },
  mailTo: string;
  showEh2InternalFeatures?: boolean;
  showH2Predict?: boolean;
}

export type SidebarActiveItem =
  | 'lcoh'
  | 'lcohplus'
  | 'pce'
  | 'whitepapers'
  | 'marketAnalytics'
  | 'productDocuments'
  | 'websiteAnalytics'

export function Sidebar(props: SidebarProps) {
  const classes = useSidebarStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const dashboardsMenuController = useMenuController();
  const handleClickOfftaker = () => {
    props.clickHandlers.offtaker();
    dashboardsMenuController.closeMenu();
  };

  const handleClickGepb = () => {
    props.clickHandlers.gepb();
    dashboardsMenuController.closeMenu();
  };

  const handleClickElectrolysisManufacturingDashboard = () => {
    props.clickHandlers.electrolysisManufacturingDashboard();
    dashboardsMenuController.closeMenu();
  }

  const handleClickUserAnalytics = () => {
    props.clickHandlers.userAnalytics();
    dashboardsMenuController.closeMenu();
  };

  const documentsMenuController = useMenuController();
  const websiteAnalyticsController = useMenuController();

  return (
    <div className={classes.root}>
      <IconButton />
      <IconButton
        onClick={open}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        onClose={close}
        className={classes.drawer}
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <a href="https://eh2.com">
            {props.logo}
          </a>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.lists}>
          <List
            subheader={
              <ListSubheader className={classes.menuSubheader}>EH2 Analytics Suite</ListSubheader>
            }
          >
            <ListItem
              button
              component={Link}
              href="https://eh2.com/toolkit"
              className={classes.listItem}
            >
              <ListItemText
                primary="LCOH Toolkit page"
                secondary="Go back to the eh2.com toolkit page"
              />
              <OpenInNewIcon className={classes.menuItemIcon} fontSize="small" />
            </ListItem>

            <ListItem
              button
              key="slcoh"
              onClick={props.clickHandlers.slcoh}
              className={clsx(classes.listItem, classes.listItemIndented, {
                [classes.listItemActive]: props.activeItem === 'lcoh'
              })}
            >
              <ListItemText
                primary="EH2–LCOH V4.1"
              />
              <ChevronRightIcon className={classes.menuItemIcon} />
            </ListItem>

            <ListItem
              button
              key="dlcoh"
              onClick={props.clickHandlers.dlcoh}
              className={clsx(classes.listItem, classes.listItemIndented, {
                [classes.listItemActive]: props.activeItem === 'lcohplus'
              })}
            >
              <ListItemText
                primary={<span>EH2–LCOH+<sup style={{ fontSize: '0.7em' }}>®</sup> V5.0</span>}
              />
              <ChevronRightIcon className={classes.menuItemIcon} />
            </ListItem>

            {props.showH2Predict && (
              <ListItem
                button
                component={Link}
                href="/h2predict"
                key="h2predict"
                className={clsx(classes.listItem, classes.listItemIndented)}
              >
                <ListItemText
                  primary="H2Predict"
                />
                <ChevronRightIcon className={classes.menuItemIcon} />
              </ListItem>
            )}

            {/*<ListItem*/}
            {/*  button*/}
            {/*  key="pce"*/}
            {/*  onClick={props.clickHandlers.pce}*/}
            {/*  className={clsx(classes.listItem, classes.listItemIndented, {*/}
            {/*    [classes.listItemActive]: props.activeItem === 'pce'*/}
            {/*  })}*/}
            {/*>*/}
            {/*  <ListItemText*/}
            {/*    primary="Project Cost Estimator (EH2 PCE)"*/}
            {/*  />*/}
            {/*  <ChevronRightIcon className={classes.menuItemIcon} />*/}
            {/*</ListItem>*/}

            {props.paths?.whitepapers && (
              <ListItem
                button
                key="whitepapers"
                component={Link}
                href={props.paths.whitepapers}
                className={clsx(classes.listItem, {
                  [classes.listItemActive]: props.activeItem === 'whitepapers'
                })}
              >
                <ListItemText primary="EH2 Whitepapers" />
                <ChevronRightIcon className={classes.menuItemIcon} />
              </ListItem>
            )}

            {props.showEh2InternalFeatures && (
              <>
                <ListItem
                  button
                  key="dashboards"
                  onClick={dashboardsMenuController.openMenu}
                  className={clsx(classes.listItem, {
                    [classes.listItemActive]: props.activeItem === 'marketAnalytics'
                  })}
                >
                  <ListItemText primary="Market Analytics" />
                  <ChevronRightIcon className={classes.menuItemIcon} />
                </ListItem>
                <Menu
                  open={dashboardsMenuController.isOpen}
                  anchorEl={dashboardsMenuController.anchorEl}
                  onClose={dashboardsMenuController.closeMenu}
                  style={{ left: 288, top: 24 }}
                >
                  <List>
                    <ListItem button onClick={handleClickOfftaker}>
                      <ListItemText primary="Hydrogen Offtaker Map"/>
                      <ChevronRightIcon className={classes.menuItemIcon} />
                    </ListItem>
                    <ListItem button onClick={handleClickGepb}>
                      <ListItemText primary="Global Electrolysis Project Dashboard"/>
                      <ChevronRightIcon className={classes.menuItemIcon} />
                    </ListItem>
                    <ListItem button onClick={handleClickElectrolysisManufacturingDashboard}>
                      <ListItemText primary="Electrolysis Manufacturing Dashboard"/>
                      <ChevronRightIcon className={classes.menuItemIcon} />
                    </ListItem>
                  </List>
                </Menu>
              </>
            )}
          </List>
          <List>
            {props.showEh2InternalFeatures && (
              <>
                <ListItem
                  button
                  key="documents"
                  onClick={documentsMenuController.openMenu}
                  className={classes.listItem}
                >
                  <ListItemText primary="EH2 Product Documents" />
                  <ChevronRightIcon className={classes.menuItemIcon} />
                </ListItem>
                <Menu
                  open={documentsMenuController.isOpen}
                  anchorEl={documentsMenuController.anchorEl}
                  onClose={documentsMenuController.closeMenu}
                  style={{ left: 288, top: 12 }}
                >
                  <List>
                    <ListItem button component={Link} target="_blank" href="https://electrich2.sharepoint.com/:b:/s/Product_Market/EcjhCbKYriVOkTin4m35lfUBlMj2AaUO3MEN8CNbES9rWA?email=soufien%40eh2.com&e=igLJ9F">
                      <ListItemText primary="Product spec sheet"/>
                      <OpenInNewIcon className={classes.menuItemIcon} fontSize="small" />
                    </ListItem>
                    <ListItem button component={Link} target="_blank" href="https://electrich2.sharepoint.com/:b:/s/Product_Market/EQl_Xw-6EYxIj7FXD2G_OvsBdcjJ7fb4BAygPQpFAGxJUg?email=soufien%40eh2.com&e=PcZr8e">
                      <ListItemText primary="Plant layout" />
                      <OpenInNewIcon className={classes.menuItemIcon} fontSize="small" />
                    </ListItem>
                    <ListItem button component={Link} target="_blank" href="https://electrich2.sharepoint.com/:b:/s/Product_Market/ERU6Xzrgxj1BjXm29g3_GJ4Bh3tDd0fGbBxAJ6cJCzctBg?email=soufien%40eh2.com&e=9sKUFw">
                      <ListItemText primary="EH2 in a nutshell" />
                      <OpenInNewIcon className={classes.menuItemIcon} fontSize="small" />
                    </ListItem>
                  </List>
                </Menu>
                <ListItem
                  button
                  onClick={websiteAnalyticsController.openMenu}
                  className={clsx(classes.listItem, {
                    [classes.listItemActive]: props.activeItem === 'websiteAnalytics'
                  })}
                >
                  <ListItemText primary="Website Analytics"/>
                  <ChevronRightIcon className={classes.menuItemIcon} />
                </ListItem>
                <Menu
                  open={websiteAnalyticsController.isOpen}
                  anchorEl={websiteAnalyticsController.anchorEl}
                  onClose={websiteAnalyticsController.closeMenu}
                  style={{ left: 288 }}
                >
                  <List>
                    <ListItem button onClick={handleClickUserAnalytics}>
                      <ListItemText primary="User Analytics"/>
                      <ChevronRightIcon className={classes.menuItemIcon} />
                    </ListItem>
                  </List>
                </Menu>
              </>
            )}

            <ListItem
              button
              key="contact"
              component={Link}
              href={`mailto: ${props.mailTo}`}
              className={classes.listItem}
            >
              <ListItemText primary="Contact" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}
